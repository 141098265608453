@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
    /* IE10+ specific styles go here */

    .btn-menu {
        background: transparent url("/wp-content/themes/raymondblanc-wordpress/images/ie/icons/rb-icon-menu.png") no-repeat center;
    }
    .btn-search {
        background: transparent url("/wp-content/themes/raymondblanc-wordpress/images/ie/icons/rb-icon-search.png") no-repeat center;
    }
    .btn-cancel {
        background: transparent url("/wp-content/themes/raymondblanc-wordpress/images/ie/icons/rb-icon-cancel.png") no-repeat center;
    }
    .menu-item--home {
        background: transparent url("/wp-content/themes/raymondblanc-wordpress/images/ie/icons/rb-icon-home.png") no-repeat center;
        background-position-x: 24px;
    }
    .menu-item--recipes {
        background: transparent url("/wp-content/themes/raymondblanc-wordpress/images/ie/icons/rb-icon-serves.png") no-repeat center;
        background-position-x: 24px;
    }
    .menu-item--experiences {
        background: transparent url("/wp-content/themes/raymondblanc-wordpress/images/ie/icons/rb-icon-experiences.png") no-repeat center;
        background-position-x: 24px;
    }
    .menu-item--about-raymond {
        background: transparent url("/wp-content/themes/raymondblanc-wordpress/images/ie/icons/rb-icon-toque.png") no-repeat center;
        background-position-x: 24px;
    }
    .menu-item--news {
        background: transparent url("/wp-content/themes/raymondblanc-wordpress/images/ie/icons/rb-icon-news.png") no-repeat center;
        background-position-x: 24px;
    }
    .menu-item--partners {
        background: transparent url("/wp-content/themes/raymondblanc-wordpress/images/ie/icons/rb-icon-partners.png") no-repeat center;
        background-position-x: 24px;
    }
    .menu-item--shop {
        background: transparent url("/wp-content/themes/raymondblanc-wordpress/images/ie/icons/rb-icon-shop.png") no-repeat center;
        background-position-x: 24px;
    }
    .menu-item--contact {
        background: transparent url("/wp-content/themes/raymondblanc-wordpress/images/ie/icons/rb-icon-contact.png") no-repeat center;
        background-position-x: 24px;
    }

    .section--alert-panel {
        display: block;
    }
}